/*=================Header=====================*/
@import '../variables';

#header {
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  .container.py-3 {
    width: 86%;
    padding: 0;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }

  .col-2 {
    flex: 1;
    max-width: 200px;

    // .logo {
    //   // height: 60px;

    //   img.img-fluid {
    //     max-width: 100%;
    //     height: 100%;
    //   }

    //   img.logo-white {
    //     display: none;
    //   }
    // }
  }

  .col-7 {
    flex: 1;
    max-width: calc(100% - 530px);

    .center-menu {
      ul.nav {
        // height: 60px;
        align-items: center;

        li {
          position: relative;

          a.nav-link {
            font-family: 'chipmong-kh','chipmong', 'sans-serif';
            // font-weight: 700;
            font-size: 13px;
            display: inline-block;
            // line-height: 20px;
            // padding: 10px 0 10px 15px;
            text-transform: uppercase;

            &.icon {
              padding: 0 5px;
              font-size: 14px;
            }
          }

          ul.dropdown-menu {
            margin: 0;
            top: 100%;
            width: 500px;
            transform: translate(-40%, 0%);
            flex-wrap: wrap;
            padding: 15px;

            li {
              flex: 0 0 50%;
              max-width: 50%;

              a.dropdown-item {
                white-space: inherit;
                padding: 5px;

                h5.menu {
                  font-size: 18px;
                  letter-spacing: 1px;
                }

                p {
                  font-size: 12px;
                  margin-bottom: 0;
                  font-weight: bold;
                }
              }
            }

          }
        }

        li.nav-item:nth-child(4) {
          ul.dropdown-menu {
            width: 650px;

            li {
              flex: 0 0 33.33%;
              max-width: 33.33%;
            }
          }
        }

        li.nav-item:hover {
          .dropdown-menu {
            display: flex;

          }
        }

        li.nav-item.show {
          .dropdown-menu {
            display: none;

          }
        }


      }
    }
  }

  .col-3 {
    flex: 1;
    max-width: 330px;

    .right-menu {
      ul.nav {
        // height: 60px;
        align-items: center;

        li {
          position: relative;

          a.nav-link {
            font-family: 'chipmong-kh', chipmong, sans-serif;
            // font-weight: 700;
            font-size: 13px;
            color: $main;
            display: inline-block;
            // line-height: 20px;
            // padding: 10px 0 10px 15px;
            text-transform: uppercase;

            &.icon {
              padding: 0 5px;
              font-size: 14px;
            }
          }

          &.nav-item.btn-book {
            padding-right: 15px;
            position: relative;

            a {
              border: 2px solid #CD901B;
              border-radius: 4px;
              // padding: 8px 20px;
            }

            // &::before {
            //   content: "|";
            //   position: absolute;
            //   left: -30px;
            //   top: 0;
            //   font-size: 30px;
            //   opacity: .5;

            // }
          }

          &.lang {
            a.nav-link#sub-menu {
              padding: 10px 15px;

              &::after {
                display: inline-block;
                width: 0;
                height: 0;
                margin-left: .255em;
                vertical-align: .255em;
                content: "";
                border-top: .3em solid #aaa;
                border-right: .3em solid transparent;
                border-bottom: 0;
                border-left: .3em solid transparent;
              }
            }

            a.icon#sub-menu {
              padding: 0 5px;
            }

            .lang-img {
              width: 30px;
              height: 30px;
              overflow: hidden;
              display: flex;
              border-radius: 50%;
              align-items: center;
              float: left;

              img {
                height: 30px;

              }
            }

            .label {

              font-weight: 500;
              color: #151414;
              display: inline-block;
              margin-left: 15px;
              margin-top: 5px;
            }

            .dropdown-menu {
              width: auto;
              left: 15%;

              li {
                flex: 0 0 100%;
                max-width: 100%;

                .label {
                  margin-top: 5px;
                  font-family: 'chipmong-kh-bold', chipmong, sans-serif;

                }
              }
            }
          }

          &#bar-mobile {
            display: none;
          }

          li.nav-item.icon-book.sm-screen {
            display: none;
          }
        }
      }

    }
  }

  #mobile-menu {
    opacity: 0;
    visibility: hidden;
    position: fixed;
    left: -15%;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: #f2f2f2d1;
    z-index: 9999;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -ms-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;

    .list-menu {
      position: relative;
      padding-left: 10%;
      padding-top: 10%;
      width: 70%;
      background: #ffffff;
      height: 100vh;
      overflow-y: scroll;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #F5F5F5;
      }

      &::-webkit-scrollbar {
        width: 0;
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      ul.nav {
        width: 400px;
        position: relative;

        li.nav-item {
          position: relative;

          a.nav-link {
            font-family: 'chipmong-kh','chipmong', 'sans-serif';
            font-size: 13px;
          }

          span.icon-sub {
            position: absolute;
            top: 0;
            right: 0;
            height: 40px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
          }

          ul.nav.sub-menu {
            display: none;
            width: 400px;
            padding: 10px;
            background-color: #efefef;

            li {
              a {
                h5.menu {
                  font-size: 16px;
                }

                .des-sub p {
                  font-family: 'chipmong-kh', chipmong, sans-serif;
                  font-weight: bold;
                  font-size: 12px;
                }
              }
            }
          }

          &.lang {
            a.nav-link {
              font-family: "chipmong-kh", "chipmong", "sans-serif";
              float: left;

              .lang-img {
                width: 30px;
                height: 30px;
                overflow: hidden;
                display: flex;
                border-radius: 50%;
                align-items: center;

                img {
                  height: 30px;
                }
              }
            }

          }
        }
      }

      .icon-close {
        &.left, &.top {
          position: absolute;
          z-index: 99999;
          padding: 10px;
          cursor: pointer;
          font-size: 18px;
          height: 45px;
          width: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.left {
          left: 3%;
          top: 20%;
          background-color: #efefef;
          padding: 10px;
          cursor: pointer;
          font-size: 14px;
          height: 100px;
          width: 45px;
        }

        &.top {
          right: 10px;
          top: 10px;
          background-color: #efefef;
        }
      }
    }


  }

  &.fixed {
    // position: relative;
    //height: 120px;
    // height: 130px;
    // display: inline-block;
    //.logo{
    //    display: none;
    //}
    .row .col-2 .logo {
      // height: 60px;
      img.img-fluid.color {
        display: block !important;
      }

      // img.img-fluid.logo-white {
      //   display: none !important;
      // }
    }

    .container.py-3 {
      position: fixed;
      top: 0;
      width: 100%;
      padding: 0 7% !important;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
      z-index: 9999;
      background-color: #ffffff;

      .row {
        padding: 10px 0;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }

  }

  .btn-book-fix {
    display: none;
    position: fixed;
    right: 0;
    bottom: 100px;
    background: #cdcdcd75;
    z-index: 999;
    font-family: 'chipmong-kh-bold', chipmong-bold, sans-serif;

    a {
      border-radius: 4px 0 0 4px;
      padding: 5px 10px;
      background: #e5e5e5f0;
    }
  }
}

/* =========== Responsive ==========*/
@media only screen and (max-width: 1240px) {
  #header {
    .col-7 {
      max-width: calc(100% - 580px);

      .center-menu {
        display: none;
      }
    }

    .col-3 {
      max-width: 380px;

      .right-menu {
        ul.nav li {
          &.nav-item.btn-book::before {
            display: none;
          }

          &#bar-mobile {
            display: flex;

            a {

              font-family: "chipmong-kh", "chipmong", "sans-serif";
              padding: 5px 15px;
              font-size: 13px;
              line-height: 26px;
            }
          }

        }
      }
    }

    &.show {
      #mobile-menu {
        display: block;
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }

    li.nav-item.icon-book.sm-screen a {
      position: fixed;
      right: 15px;
      bottom: 18%;
      z-index: 9999999;
    }

    .btn-book-fix {
      display: inline-block;
    }
  }
}

@media only screen and (max-width: 1024px) {
  #header {
    .right-menu {
      li.nav-item.btn-book, li.nav-item.lang {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  #header {
    .container.py-3 {
      width: 94%;

      #mobile-menu .list-menu {
        padding-left: 15%;
      }

      #mobile-menu .icon-close.left {

      }
    }

    &.fixed {
      .container.py-3 {
        width: 100%;
        padding: 0 25px !important;
      }
    }
  }
}

@media only screen and (max-width: 769px) {
  #header #mobile-menu .list-menu {
    padding-left: 10%;
    width: 85%;

    .icon-close.left {

      font-size: 12px;
      width: 35px;
    }

    .icon-close.top {
      height: 40px;
      width: 40px;
    }
  }


}

@media only screen and (max-width: 576px) {
  #header {
    .container.py-3 {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }

    #mobile-menu {
      .list-menu {

        .icon-close.top {
          font-size: 15px;
          right: 10px;
          top: 10px;
          height: 35px;
          width: 35px;
        }

        ul.nav {
          width: 100%;

          li.nav-item {
            span.icon-sub {
              right: 10px;
            }

            ul.nav.sub-menu {
              background-color: transparent;
              width: 100%;
              padding: 0;

              a.nav-link {
                display: inline-block;
                margin-left: 10px;

                .des-sub {
                  display: none;
                }

                h5.menu {
                  margin: 0;
                }
              }
            }

          }
        }
      }

      .list-menu {
        padding-top: 50px;
        padding-left: 65px;
      }
    }

    .col-2 .logo {
      //max-width: 100px;
    }

    .col-3 .right-menu ul.nav {
      //height: 30px;
      li#bar-mobile a {
        padding: 0;
        font-size: 22px;
      }
    }


  }
}

@media only screen and (max-width: 480px) {
  #header {
    .container.py-3 {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
    }
    #mobile-menu .list-menu ul.nav {

      width: 100%;
      li.nav-item ul.nav.sub-menu {
        width: 100%;
      }
    }
  }
}

