.breadcrumb-wrap{
    background: $gray;
    max-height: 120px;
    .breadcrumb{
        background: $gray;
        height: 80px;
        margin: auto;
        width: 100%;
        padding: 0.75rem 115px;
        .breadcrumb-item{
            align-items: center;
            font-size: 17px;
            a{
                color: #aaa;
                font-weight: 200;
                font-size: 18px;
                &:hover{
                    text-decoration: none;
                }
            }
            &.active{
                span{
                    font-weight: bold;
                    color: $text-bold;
                    font-size: 18px;
                }
            }
        }

        .breadcrumb-item + .breadcrumb-item::before {
            display: inline-block;
            padding-right: 0.5rem;
            color: $text-gray;
            content: "/";
        }
    }
}
