html:lang(kh) body{

  font-family: 'chipmong-kh', chipmong , sans-serif;
  // line-height: normal;
  h1,h2,h3,h4,h5,h6{
    font-family: 'chipmong-kh-bold', chipmong-bold , sans-serif;
    // line-height: normal;
  }
  // section.slide .carousel-item .caption h3.title{
  //   line-height: 70px;
  //   font-size: 46px;
  // }
  // .blog{
  //   .title{
  //     h4{
  //       //line-height: 30px;
  //       &.two-line{
  //         -webkit-line-clamp: 1;
  //       }
  //     }
  //   }
  // }
  // footer a.nav-link{
  //   line-height: 32px;
  // }
  @media only screen and (max-width: 1024px) {
    section.slide .carousel-item .caption {
      .sub-title h6{
        font-size: 24px;
      }
      h3.title{
        line-height: 54px;
        font-size: 36px;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    section.slide .carousel-item .caption {
      .sub-title h6{
        font-size: 20px;
        line-height: 24px;
      }
      h3.title{
        font-size: 30px;
        padding-bottom: 0;
      }
      .btn-book a{
        padding: 10px 20px;
      }
    }
    .blog{
      .title{
        h4{
          font-size: 17px;
        }
      }
    }
  }
  @media only screen and (max-width: 576px) {
    section.slide .carousel-item .caption {
      .sub-title{
        border-bottom: none;
        h6{
          font-size: 14px;
          line-height: 18px;
        }
      }
      h3.title{
        // line-height: 27px;
        font-size: 18px;
        // padding-bottom: 0;
      }
      .btn-book a{
        padding: 5px 15px;
        font-size: 10px;
      }
    }
  }
}