//
footer {
  background-color: #114676;
  padding: 3% 0 20px 0;

  .container {
    .row:first-child {
      border-bottom: 1px solid #ffffff91;
    }
  }

  h5.title {
    color: white;
    font-size: 24px;
    font-weight: 700;
  }

  a.nav-link {
    color: white;
    padding: 0;
    display: inline-block;
  }

  p.text-body,
  .recent-post .nav-link {
    color: #ffffff91 !important;
  }

  .foot-logo {
    width: 250px;
  }

  .foot-logo-right {
    width: 100px;
    float: right;

    img.img-fluid {
      max-width: 100%;
      height: 100%;

    }
  }

  #back-to-top {
    position: fixed;
    right: 15px;
    bottom: 50px;
    z-index: 3;

    .icon {
      opacity: 0;
      visibility: hidden;
      height: 0;
      width: 0;
      background-color: #cdcdcd75;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 26px;
      color: #002d54;
      border-radius: 3px;
      cursor: pointer;
      -webkit-transition: 0.3s;
      -moz-transition: 0.3s;
      -ms-transition: 0.3s;
      -o-transition: 0.3s;
      transition: 0.3s;
    }

    // &:hover {
    //   .icon {
    //     color: white;
    //   }
    // }

    &.show {
      .icon {
        opacity: 1;
        visibility: visible;
        height: 40px;
        width: 40px;
      }
    }
  }

}

// 11 05 2023 =======================================================
.company img {
  width: 25px;
  margin-right: 15px;
  float: left;
}

.company ul li {
  color: white;
  padding: 10px 0;
  font-size: 15px;
  font-family: 'chipmong-kh', 'chipmong';
}

.company ul {
  padding: 0;
  list-style: none;
}

.social-media ul li {
  display: inline-block;
  padding: 5px;
}

.social-media ul {
  list-style: none;
  margin: 0;
  padding: 20px 0;
}

.social-media ul li img {
  width: 45px;
}
.text-white p span {
  font-size: 15px !important;
}
#weatherWidget #openWeather svg {
  display: none;
}

// 11 05 2023 =======================================================
@media only screen and (max-width: 668px) {
  footer {
    .foot-logo {
      width: 160px;
    }

    .foot-logo-right {
      width: 100px;
    }

    .title {
      font-size: 22px !important;
    }

    .text-white {
      p {
        font-size: 14px;
      }
    }

    .nav {
      .nav-item {
        a {
          font-size: 14px;
        }
      }
    }
  }

  #ww_ca4cab591d224 .ww-box {
    vertical-align: baseline;
    padding: 0;
    margin: 0;
    padding: 0 !important;
  }
}
