
//Fontawesome
//@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/dist/css/bootstrap.css';
@import '~font-awesome/css/font-awesome.min.css';
@import "../fontawesome";

// Bootstrap
@import '~select2/dist/css/select2.min.css';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import '../bootstrap-custom';
@import '../variables';

@import "~@splidejs/splide/dist/css/splide-core.min.css";
@import "~@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
@import "~slick-carousel/slick/slick.css";
// @import "~slick-carousel/slick/slick-theme.css";
@import '~swiper/swiper';

//======Layout Page========//
@import"kh-style";
@import "header";
@import "footer";
@import "breadcrumb";

